import i18n from '@/plugins/i18n'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { EventManagerInterface } from '@tracktik/tt-event-manager'
import { WidgetStoreModel } from '@/tt-widget-factory'
import { AppContextEventPayload } from '@/tt-app-context'

const clearWidgetStore = (): void => {
  WidgetStoreModel.setSelected(null)
  WidgetStoreModel.setNew(null)
}

const confirm = (
  eventManager: EventManagerInterface<AppContextEventPayload>,
  message: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
      message,
      accept: () => resolve(),
      cancel: () => reject(),
    })
  })
}

export const askConfirmationToDiscardChanges = (
  eventManager: EventManagerInterface<AppContextEventPayload>,
): Promise<void> =>
  confirm(eventManager, i18n.tc('widgets.discard_changes_question'))

export const askConfirmationToClear = (
  eventManager: EventManagerInterface<AppContextEventPayload>,
  needConfirmation: boolean,
): Promise<void> => {
  if (!needConfirmation) {
    clearWidgetStore()

    return Promise.resolve()
  }

  const message = i18n.tc('widgets.discard_changes_question')

  return confirm(eventManager, message).then(
    () => clearWidgetStore(),
    () => Promise.reject(),
  )
}
